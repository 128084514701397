import { CheckCircleOutlined, DownOutlined, ExportOutlined, ImportOutlined, PrinterOutlined } from '@ant-design/icons'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Button, Div, Icon, Input, P, SmallText } from '@konsys-ui-custom'
import {
  Button as ButtonAnt, Checkbox, Dropdown, Menu,
  Modal,
  Popconfirm, Radio, Select as SelectAnt, Upload,
  message,
  notification,
} from 'antd'
import _, { dropRight, find, isNil, keys } from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import {
  CANCEL_UPSELL,
  CONFIRM_PAYMENT,
  GENERATE_KERRY_TRACKING_NUMBER, IMPORT_EMS_COD, IMPORT_KERRY_COD, IMPORT_KERRY_TRACKING_NUMBERS,
  SET_DEFAULT_DELIVERY_CHANNEL, VERIFY_SLIP,
} from '../../api/mutation'
import {
  BANK_LIST, CHAT_PROVIDERS, DEFAULT_DELIVERY_CHANNEL, SALE_ORDERS, SO_STATUS_LIST,
} from '../../api/query'
import {
  ListContainer, RangePicker, Select, Uploader,
} from '../../components'
import History from '../../components/common/history'
import { SO_TYPE } from '../../constants/enum'
import { AuthorizationContext } from '../../store/StoreProvider'
import { theme } from '../../styles/_variables'
import { delayInput, showMessageMutation } from '../../utils'
import { readExcelFile } from '../../utils/xlsx-helper'
import { ADDRESS_LABEL_STATUS, DELIVERY_CHANNEL, DELIVERY_TYPE, SO_STATUS } from './constants'
import QuickView, { PaymentTabDetail } from './quick-view'
import { ExportSalesOrders } from './sub-pages'
import { soStatusGroupMaptoText, soStatusMaptoColor, soStatusMaptoText } from './utils'

const KEY = 'salesOrderList'

export default (props) => {
  const [searchText, setSearchText] = useState(props.location?.state?.search || '')
  const [filterChatProviderId, setFilterChatProviderId] = useState()
  const [filterLoading, setFilterLoading] = useState(false)
  const [dateType, setDateType] = useState('createdDate')
  const [date, setDate] = useState([moment().subtract(1, 'M'), moment()])
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState()
  const [filterAddressLabelStatus, setFilterAddressLabelStatus] = useState()
  const [filterEdited, setFilterEdited] = useState()
  const [selectedType] = useState()
  const [verifySlipModal, setVerifySlipModal] = useState(false)
  const [myUpsell, setMyUpsell] = useState(false)
  const [slipRefNumber, setSlipRefNumber] = useState()
  const [slipImage, setSlipImage] = useState()
  const [bankCode, setBankCode] = useState('')
  const [disabledPopconfirm, setDisabledPopconfirm] = useState(true)
  const [orderField, setOrderField] = useState('createdAt')
  const [orderBy, setOrderby] = useState('DESC')
  const { loading: chatProvidersLoading, data: chatProvidersData } = useQuery(CHAT_PROVIDERS, { variables: { isWithoutPage: true } })
  const { data: defaultDeliveryChannelData } = useQuery(DEFAULT_DELIVERY_CHANNEL)
  const [doVerifySlip, { loading: verifyLoading, data: verifyData }] = useMutation(VERIFY_SLIP)
  const [doChangeDefaultDeliveryChannel] = useMutation(SET_DEFAULT_DELIVERY_CHANNEL)
  const [doGenerate, { loading: generating }] = useMutation(GENERATE_KERRY_TRACKING_NUMBER)
  const [doImportKerryTrackingNumbers, { loading: importingKerry }] = useMutation(IMPORT_KERRY_TRACKING_NUMBERS)
  const [doImportEMSCOD, { loading: importingEMSCOD }] = useMutation(IMPORT_EMS_COD)
  const [doConfirmVerifySlip, { loading: confirmLoading }] = useMutation(CONFIRM_PAYMENT)
  const [doCancelUpsell, { loading: cancelingUpsell }] = useMutation(CANCEL_UPSELL)
  const { authorization } = useContext(AuthorizationContext)
  const { data, loading, fetchMore, refetch } = useQuery(SALE_ORDERS, {
    variables: {
      search: searchText,
      limit: 10,
      offset: 0,
      ...dateType === 'createdDate'
        ? {
          startCreatedDate: date?.[0],
          endCreatedDate: date?.[1],
          startDeliveryDate: undefined,
          endDeliveryDate: undefined,
        }
        : {
          startDeliveryDate: date?.[0],
          endDeliveryDate: date?.[1],
          startCreatedDate: undefined,
          endCreatedDate: undefined,
        },
      status: selectedStatus,
      orderBy,
      orderField,
      chatProviderId: filterChatProviderId,
      addressLabelStatus: filterAddressLabelStatus,
      isEdited: filterEdited,
      salesOrderType: selectedType,
      myUpsell,
    },
  })
  const [getSoStatus, { data: statusData, loading: gettingSoStatus }] = useLazyQuery(SO_STATUS_LIST)
  const { data: bankData } = useQuery(BANK_LIST)

  useEffect(() => {
    if (!disabledPopconfirm) {
      document.getElementById('modal-button-confirm').click()
    }
  }, [disabledPopconfirm])

  const clearModal = () => {
    setSlipRefNumber()
    setBankCode('')
    setSlipImage()
  }

  const mapped = (!loading || !_.isNil(data))
    && data?.[KEY]?.data?.list?.map((v) => ({
      id: v.soNumber,
      title: <P>{v.soNumber} {v.addressLabelStatus === 'PRINTED' ? <Icon margin='0 0 0 4px' icon='fal fa-print' /> : ''}</P>,
      right: <SmallText bold textAlign="right" color={soStatusMaptoColor(v.status)} style={{ display: 'flex', alignItems: 'center' }}>
        {
          v.editCount > 0 && <span style={{ marginRight: 6, display: 'flex', flexDirection: 'row', alignItems: 'center', borderRadius: 6, border: `1px solid ${theme.color.error}`, color: theme.color.error, padding: '1px 4px' }}>
            <Icon icon='fal fa-pencil' color={theme.color.error} style={{ marginRight: 2 }}/>
            {v.editCount}
          </span>
        }
        {soStatusMaptoText(v.status)}
      </SmallText>,
      content: {
        one: {
          title: {
            text: 'ชื่อลูกค้า',
          },
          content: {
            text: v.customer.name,
          },
        },
        three: {
          title: {
            text: 'ยอดชำระ',
          },
          content: {
            text: numeral(v.subtotalAfterDiscount).format('0,0.00'),
          },
        },
      },
      detail: {
        title: _.filter(
          [
            {
              id: 'detail',
              text: 'รายละเอียด',
              icon: 'fal fa-info-square',
            },
            {
              id: 'account',
              text: 'การเงิน',
              icon: 'fal fa-money-check-alt',
            },
            {
              id: 'history',
              text: 'ประวัติการใช้งาน',
              icon: 'fal fa-history',
            },
          ],
          (o) => (authorization.salesOrder.viewHistoryLog ? true : o.id !== 'history'),
        ),
        buttonList: [
          authorization.salesOrder.edit && v.lots[0].deliveryType === DELIVERY_TYPE.DELIVERY && isNil(v.ordersn)
          && <Dropdown key='addres-label-dropdown' trigger='click' overlay={
            <Menu>
              <Menu.Item
                key="EMS"
                onClick={() => {
                  doGenerate({ variables: { soId: v.id, deliveryChannel: DELIVERY_CHANNEL.EMS.KEY } }).then((resp) => showMessageMutation(resp.data?.generateKerryTrackingNumber))
                }}
              >
                EMS
              </Menu.Item>
              {/* <Menu.Item
                key="KERRY"
                onClick={() => {
                  doGenerate({ variables: { soId: v.id, deliveryChannel: DELIVERY_CHANNEL.KERRY.KEY } }).then((resp) => showMessageMutation(resp.data?.generateKerryTrackingNumber))
                }}
              >
                Kerry
              </Menu.Item> */}
            </Menu>
          }>
            <Button
              small
              ghost
              loading={generating}
              margin="4px 8px 4px 0"
              text="สร้างเลข Tracking Number ใหม่"
              icon="far fa-rotate-right"
            />
          </Dropdown>,
          authorization.salesOrder.edit
            && <Link
              key="payment"
              to={{
                pathname: `${props.match.url}/payment/${v.id}`,
                state: { soRef: v.soNumber },
              }}
            >
              <Button
                small
                ghost
                margin="4px 8px 4px 0"
                text="บันทึกชำระเงิน"
                icon="far fa-check-circle"
              />
            </Link>,
          authorization.purchaseOrder.create
            && v.status === SO_STATUS.WAITING_PO && (
            <Link to="/purchase-orders/create" key="create_po">
              <Button
                small
                inverse
                color={theme.color.success}
                margin="4px 8px 4px 0"
                text="สร้างใบสั่งซื้อ"
                icon="far fa-plus"
              />
            </Link>
          ),
          authorization.salesOrder.edit
            && (
              <Div>
                <Modal
                  title="ตรวจสอบสลิป"
                  visible={verifySlipModal}
                  destroyOnClose={true}
                  onCancel={() => {
                    setDisabledPopconfirm(true)
                    setVerifySlipModal(false)
                    clearModal()
                  }}
                  footer={[
                    <Button
                      small
                      key="cancel"
                      text="ยกเลิก"
                      onClick={() => {
                        setVerifySlipModal(false)
                        setSlipRefNumber()
                      }}
                    />,
                    <Popconfirm
                      key="submit"
                      title={
                        verifyData?.verifySlip?.data?.totalPayment
                        < verifyData?.verifySlip?.data?.depositAmount ? (
                            <P>
                            ยอดชำระรวม ยังไม่ครบยอดที่กำหนดชำระ
                              <br />
                            ต้องบันทึกการชำระเงินเพิ่มเติม
                            จนถึงยอดที่กำหนดของใบออเดอร์ จึงจะดำเนินการต่อได้
                              <br />
                            ต้องการบันทึกชำระเงินหรือไม่ ?
                            </P>
                          ) : verifyData?.verifySlip?.data?.totalPayment
                          > verifyData?.verifySlip?.data?.depositAmount ? (
                              <P>
                            ยอดชำระรวม เกินยอดที่กำหนดของใบออเดอร์
                                <br />
                            ต้องการบันทึกชำระเงินหรือไม่ ?
                              </P>
                            ) : (
                              <P>Loading...</P>
                            )
                      }
                      onConfirm={() => {
                        doConfirmVerifySlip({
                          variables: {
                            soId: v.id,
                          },
                        }).then((resp) => {
                          if (resp?.data?.confirmPayment?.success) { message.success(resp?.data?.confirmPayment?.message) } else { message.error(resp?.data?.confirmPayment?.message) }
                          setVerifySlipModal(false)
                          clearModal()
                        })
                      }}
                      onCancel={() => setDisabledPopconfirm(true)}
                      onVisibleChange={(visible) => {
                        if (!visible) setDisabledPopconfirm(true)
                      }}
                      destroyTooltipOnHide
                      okText="ยืนยัน"
                      cancelText="ยกเลิก"
                      disabled={disabledPopconfirm}
                      okButtonProps={{
                        disabled: _.isEmpty(verifyData),
                      }}
                    >
                      <Button
                        id="modal-button-confirm"
                        loading={verifyLoading || confirmLoading}
                        disabled={
                          confirmLoading
                          || (_.isEmpty(slipRefNumber) && _.isEmpty(slipImage))
                          || _.isEmpty(bankCode)
                          || verifyLoading
                        }
                        small
                        key="ok"
                        text="ยืนยัน"
                        onClick={() => {
                          doVerifySlip({
                            variables: {
                              salesOrderId: parseInt(v.id),
                              transactionId: slipRefNumber,
                              bankCode,
                              attachment: _.isEmpty(_.compact(slipImage))
                                ? undefined
                                : slipImage[0],
                            },
                          }).then((resp) => {
                            if (resp.data.verifySlip.success) {
                              notification.success({
                                message: resp?.data?.verifySlip?.message,
                                placement: 'topRight',
                                style: {
                                  boxShadow: `0 0 10px ${theme.color.success}44`,
                                },
                              })
                              clearModal()
                              setVerifySlipModal(false)
                            } else if (
                              !resp?.data?.verifySlip?.success
                              && resp?.data?.verifySlip?.message
                                === 'NEED_CONFIRMATION'
                              && resp?.data?.verifySlip?.data?.totalPayment
                                !== resp?.data?.verifySlip?.data?.depositAmount
                            ) {
                              setDisabledPopconfirm(false)
                            } else {
                              notification.error({
                                message: resp?.data?.verifySlip?.message,
                                placement: 'topRight',
                                style: {
                                  boxShadow: `0 0 10px ${theme.color.error}44`,
                                },
                              })
                            }
                          })
                        }}
                      />
                    </Popconfirm>,
                  ]}
                >
                  <Div>
                    <P display="block" margin="0 0 8px">
                      เลือกธนาคาร
                    </P>
                    <SelectAnt
                      showSearch
                      style={{
                        width: '100%',
                      }}
                      placeholder="เลือกธนาคาร"
                      onSelect={(value) => setBankCode(value)}
                    >
                      {bankData?.bankList?.data?.list?.map((o) => (
                        <SelectAnt.Option key={o.bankCode} value={o.bankCode}>
                          {o.name}
                        </SelectAnt.Option>
                      ))}
                    </SelectAnt>
                    <P display="block" margin="16px 0 8px">
                      รายละเอียด
                    </P>
                    <Div
                      className="slip-section"
                      padding={!_.isEmpty(slipImage) ? '0 0 24px' : '0'}
                      display="flex"
                      alignItems="center"
                    >
                      <Uploader
                        onChange={(e) => setSlipImage(e)}
                        data={slipImage}
                        accept="image/*"
                        isMultiple={false}
                        buttonText="อัพโหลดไฟล์"
                        disabled={!_.isEmpty(slipRefNumber)}
                      />
                      <P margin="0 16px">หรือ</P>
                      <Input
                        width="61.9%"
                        state="default"
                        onChange={(e) => setSlipRefNumber(e.target.value)}
                        placeholder="กรอก Reference number จากสลิป"
                        disabled={!_.isEmpty(slipImage)}
                      />
                    </Div>
                  </Div>
                </Modal>
                <Button
                  small
                  ghost
                  margin="4px 8px 4px 0"
                  text="ตรวจสอบสลิปชำระเงิน"
                  icon="far fa-check"
                  onClick={() => setVerifySlipModal(true)}
                />
              </Div>
            ),
          (
            v.salesOrderType === SO_TYPE[0].value
              ? authorization.salesOrder.createUpdatePOS
              : authorization.salesOrder.edit
          ) && (
            // v.status !== SO_STATUS.COMPLETED
            v.status !== SO_STATUS.CANCELLED
            && v.status !== SO_STATUS.PREPARING
            && v.status !== SO_STATUS.RETURNED
          ) && (
            <Link key="edit" to={`${props.match.url}/edit/${v.id}`} target="_blank">
              <Button
                small
                ghost
                margin="4px 8px 4px 0"
                text="แก้ไขใบออเดอร์"
                icon="far fa-edit"
              />
            </Link>
          ),
          authorization.salesOrder.upsell
          && (
            v.status !== SO_STATUS.COMPLETED
            && v.status !== SO_STATUS.CANCELLED
            && v.status !== SO_STATUS.PREPARING
            && v.status !== SO_STATUS.RETURNED
          )
          && v.canUpsell
          && (
            <Link key="upsell" to={`${props.match.url}/upsell/${v.id}`} target="_blank">
              <Button
                small
                inverse
                bold
                color={theme.color.success}
                margin="4px 8px 4px 0"
                text="Upsell"
                icon="far fa-chart-line"
              />
            </Link>
          ),
          authorization.salesOrder.upsell
          && (
            v.status !== SO_STATUS.COMPLETED
            && v.status !== SO_STATUS.CANCELLED
            && v.status !== SO_STATUS.RETURNED
          ) && v.hasUpsell
          && (
            <Popconfirm
              key="submit"
              title="ยืนยันจะลบ Upsell หรือไม่ เมื่อลบแล้วจะไม่สามารถแก้ไขได้ ?"
              onConfirm={() => {
                doCancelUpsell({
                  variables: { id: v.id },
                }).then((resp) => {
                  if (resp?.data?.cancelUpsell?.success) {
                    message.success(resp?.data?.cancelUpsell?.message)
                    refetch()
                  } else {
                    message.error(resp?.data?.cancelUpsell?.message)
                  }
                })
              }}
              okText="ยืนยันลบ Upsell"
              cancelText="ยกเลิก"
              okButtonProps={{ disabled: cancelingUpsell }}
            >
              <Button
                small
                inverse
                bold
                color={theme.color.error}
                margin="4px 8px 4px 0"
                text="ยกเลิก Upsell"
                icon="far fa-chart-line"
                loading={cancelingUpsell} disabled={cancelingUpsell}
              />
            </Popconfirm>
          ),
        ],
        content: [
          <QuickView key={v.id} data={v} authorization={authorization.salesOrder}/>,
          <PaymentTabDetail
            key={1}
            data={v}
            history={props.history}
            pushState={{ state: { soRef: v.soNumber } }}
          />,
          <History key={2} data={v} moduleName="SALES_ORDER" />,
        ],
      },
    }))

  useEffect(() => {
    if (props.location?.state?.search) {
      setSearchText(props.location?.state?.search)
    }
  }, [props.location?.state?.status])

  const statusOption = statusData?.salesOrderStatusList?.data.map((v) => ({
    value: v.status,
    text: `${soStatusGroupMaptoText(v.status)} (${v.count})`,
  })) || []

  return (
    <Div>
      <Helmet>
        <meta name="viewport" content="width=1280" />
      </Helmet>
      <ListContainer
        onInfiniteScroll={() => {
          setFetchMoreLoading(true)
          fetchMore({
            variables: {
              offset: data[KEY].data.list.length,
              limit: 10,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              setFetchMoreLoading(false)
              if (!fetchMoreResult) return prev
              return { ...prev,
                [KEY]: {
                  ...fetchMoreResult[KEY],
                  data: {
                    ...fetchMoreResult[KEY].data,
                    list: [
                      ...prev[KEY].data.list,
                      ...fetchMoreResult[KEY].data.list,
                    ],
                  },
                } }
            },
          })
        }}
        title={[
          {
            text: 'รายชื่อใบออเดอร์',
            icon: 'fad fa-receipt',
          },
        ]}
        filterHeight={100.02}
        filter={[
          <Div margin="0 0 24px" key={0}>
            <Div display="flex" alignItems="center" moreStyle={{ flexGrow: 1 }}>
              <Div moreStyle={{ flexGrow: 1 }} margin="0 16px 0 0">
                <Input
                  width="100%"
                  type="text"
                  prefix={<Icon icon="prefix fal fa-search" />}
                  placeholder="ค้นหาชื่อลูกค้า, SO Number, เบอร์โทร"
                  onChange={(e) => {
                    const searchValue = e.target.value
                    delayInput(() => {
                      setSearchText(searchValue)
                    })
                  }}
                />
              </Div>
              <Select
                style={{ width: '12%', margin: '0 16px 0 0' }}
                placeholder='ช่องทางการขาย'
                options={chatProvidersData ? chatProvidersData?.chatProviders?.data.map((v) => ({
                  id: v.id, value: v.id, text: v.name, code: v.code,
                })) : []}
                onChange={(value) => setFilterChatProviderId(value)}
                value={filterChatProviderId}
                loading={chatProvidersLoading}
              />
              <Select
                onDropdownVisibleChange={(e) => {
                  if (e && isNil(statusData)) {
                    getSoStatus()
                  }
                }}
                loading={gettingSoStatus}
                style={{ width: '10%', margin: '0 16px 0 0' }}
                placeholder="สถานะใบออเดอร์"
                options={statusOption}
                onChange={(value) => setSelectedStatus(value)}
                value={selectedStatus}
              />
              <Select
                style={{ width: '10%', margin: '0 16px 0 0' }}
                placeholder='สถานะใบปะหน้า'
                options={keys(ADDRESS_LABEL_STATUS.ENUM).map((k) => ({ id: ADDRESS_LABEL_STATUS.ENUM[k], value: ADDRESS_LABEL_STATUS.ENUM[k], text: ADDRESS_LABEL_STATUS.TEXT[k] }))}
                onChange={(value) => setFilterAddressLabelStatus(value)}
                value={filterAddressLabelStatus}
              />
              <Select
                style={{ width: '10%', margin: '0 16px 0 0' }}
                placeholder='สถานะการแก้ไข'
                options={[
                  { id: 0, value: true, text: 'เคยแก้ไข' },
                  { id: 1, value: false, text: 'ไม่เคยแก้ไข' },
                ]}
                onChange={(value) => setFilterEdited(value)}
                value={filterEdited}
              />
              <RangePicker
                style={{ margin: '0 16px 0 0', width: '15%' }}
                onChange={(e) => setDate(e)}
                value={date}
              />
              <Radio.Group
                value={dateType}
                onChange={(e) => setDateType(e.target.value)}
              >
                <Radio.Button value="createdDate">วันที่สร้าง</Radio.Button>
                <Radio.Button value="deliveryDate">วันที่กำหนดส่ง</Radio.Button>
              </Radio.Group>
            </Div>

            <Div display="flex" margin="8px 0 0">
              <Div
                display="flex"
                moreStyle={{ flexGrow: 1 }}
                margin="0 56px 0 0"
              >
                <Select
                  defaultValue="createdAt"
                  onChange={(value) => setOrderField(value)}
                  value={orderField}
                  style={{
                    width: '41.65%',
                    minWidth: '200px',
                    margin: '0 16px 0 0',
                  }}
                  options={[
                    { text: 'เรียงตามวันที่สร้าง', value: 'createdAt' },
                    { text: 'เรียงตามวันที่อัพเดท', value: 'updatedAt' },
                  ]}
                />
                <Select
                  suffixIcon={
                    <Icon
                      icon={
                        orderBy === 'ASC'
                          ? 'fal fa-sort-amount-down-alt'
                          : 'fal fa-sort-amount-up-alt'
                      }
                    />
                  }
                  defaultValue="DESC"
                  value={orderBy}
                  onChange={(value) => setOrderby(value)}
                  options={[
                    { text: 'น้อยไปมาก', value: 'ASC' },
                    { text: 'มากไปน้อย', value: 'DESC' },
                  ]}
                />
                <Checkbox style={{ marginLeft: 16 }} checked={myUpsell} onChange={(e) => setMyUpsell(e.target.checked)}>งาน Upsell ของฉัน</Checkbox>
              </Div>
              <Div display="flex" alignItems="center">
                <Button
                  text="ล้าง"
                  icon="fal fa-sync"
                  small
                  ghost
                  onClick={() => {
                    setDate([])
                    setDateType('createdDate')
                    setSearchText('')
                    setSelectedStatus()
                    setFilterAddressLabelStatus()
                    setFilterEdited()
                    setFilterChatProviderId()
                    setOrderField('createdAt')
                    setOrderby('DESC')
                    setMyUpsell(false)
                    const selectedDate = dateType === 'createdDate'
                      ? {
                        startCreatedDate: undefined,
                        endCreatedDate: undefined,
                        startDeliveryDate: undefined,
                        endDeliveryDate: undefined,
                      }
                      : {
                        startDeliveryDate: undefined,
                        endDeliveryDate: undefined,
                        startCreatedDate: undefined,
                        endCreatedDate: undefined,
                      }
                    setFilterLoading(true)
                    refetch({
                      search: '',
                      status: undefined,
                      orderBy: 'DESC',
                      filterChatProviderId: undefined,
                      orderField: 'createdAt',
                      ...selectedDate,
                    }).then(() => {
                      setFilterLoading(false)
                    })
                  }}
                />
              </Div>
            </Div>
          </Div>,
        ]}
        tabLoading={fetchMoreLoading}
        listLoading={filterLoading || (loading && _.isEmpty(data))}
        list={mapped}
        buttonList={
          authorization.salesOrder.create
            ? [
              defaultDeliveryChannelData
                ? <Radio.Group
                  key='delivery-channel-setting'
                  className='delivery-channel-setting'
                  style={{ height: 32, marginRight: 16 }}
                  defaultValue={find(defaultDeliveryChannelData?.defaultDeliveryChannel?.data, (o) => o.isSet)?.deliveryChannel}
                  onChange={(e) => doChangeDefaultDeliveryChannel({
                    variables: {
                      input: { deliveryName: e.target.value },
                    },
                  }).then((resp) => {
                    showMessageMutation(resp.data?.setDefaultDeliveryChannel)
                  })}
                >
                  <Radio.Button value="EMS">EMS</Radio.Button>
                  {/* <Radio.Button value="KERRY">Kerry</Radio.Button> */}
                </Radio.Group>
                : null,
              <ButtonAnt key='upsell' style={{ marginRight: 16 }}>
                <ExportSalesOrders type='UPSELL' />
              </ButtonAnt>,
              <Dropdown key='addres-label-dropdown' trigger='click' overlay={
                <Menu>
                  <Menu.Item key="1" icon={<PrinterOutlined />}>
                    <ExportSalesOrders key='print-address-label' type='ADDRESS_LABEL_WAITING_PRINT' />
                  </Menu.Item>
                  <Menu.Item key="2" icon={<CheckCircleOutlined />}>
                    <ExportSalesOrders key='address-label' type='ADDRESS_LABEL_PRINTING' />
                  </Menu.Item>
                </Menu>
              }>
                <ButtonAnt style={{ marginRight: 16 }}>
                  ใบปะหน้า <DownOutlined />
                </ButtonAnt>
              </Dropdown>,
              <Dropdown key='kerry-dropdown' trigger='click' overlay={
                <Menu>
                  {/* <Menu.Item key="1" icon={<ExportOutlined />}>
                    <ExportSalesOrders type='KERRY_EXPORT'/>
                  </Menu.Item>
                  <Menu.Item key="2" icon={<ImportOutlined />}>
                    <Upload
                      accept='.xlsx'
                      customRequest={() => null}
                      disabled={importingKerry}
                      showUploadList={false} onChange={(info) => {
                        readExcelFile(info.file.originFileObj, 'KERRY', (convertedFile) => {
                          doImportKerryTrackingNumbers({
                            variables: {
                              input: convertedFile,
                            },
                          }).then((resp) => {
                            if (resp.data.importKerryTrackingNumbers.success) {
                              message.success(resp.data.importKerryTrackingNumbers.message)
                            } else {
                              message.error(resp.data.importKerryTrackingNumbers.message)
                            }
                          })
                        })
                      }}>
                      Import Kerry Tracking Numbers
                    </Upload>
                  </Menu.Item> */}
                  <Menu.Item key="3" icon={<ImportOutlined />}>
                    <Upload
                      accept='.xlsx,.xls'
                      customRequest={() => null}
                      disabled={importingEMSCOD}
                      showUploadList={false} onChange={(info) => {
                        readExcelFile(info.file.originFileObj, 'EMS_COD', (convertedFile) => {
                          // console.info(convertedFile)
                          doImportEMSCOD({
                            variables: {
                              input: dropRight(convertedFile),
                            },
                          }).then((resp) => {
                            if (resp.data.importEMSCOD.success) {
                              message.success(resp.data.importEMSCOD.message)
                            } else {
                              message.error(resp.data.importEMSCOD.message)
                            }
                          })
                        })
                      }}>
                      Import EMS COD
                    </Upload>
                  </Menu.Item>
                </Menu>
              }>
                <ButtonAnt style={{ marginRight: 16 }}>
                  EMS <DownOutlined />
                </ButtonAnt>
              </Dropdown>,
              <Link key="create" to={`${props.match.url}/create`}>
                <Button
                  small
                  ghost
                  margin="4px 8px 4px 0"
                  text="สร้างใบออเดอร์"
                  inverse
                  color={theme.color.success}
                  icon="far fa-plus"
                />
              </Link>,
            ]
            : []
        }
      ></ListContainer>
    </Div>
  )
}
